<!-- Title Bar -->
<div class="title-bar">
    <div class="title">
        <h1 translate title="{{ 'EVENTS' | translate }}">EVENTS</h1>
    </div>
</div>
<!-- List Panel -->
<div class="event-panel">
    <div class="event-container">
        <app-events-filter-form
            [filterName]="'eventsFilter'"
            [columns]="columns"
            [objectTypes]="objectTypes"
            [resourceTags]="resourceTags"
            downloadEvents="true"
            [typeFilters]="eventsFilter"
            [typeFilterLabel]="'TYPES'"
            (onToggleColumn)="toggleColumn($event)"
            (onApply)="applyFilters($event)"
            (onReset)="resetEvents()"
            [hasEvents]="events && events.length"
        ></app-events-filter-form>

        <!-- Table -->
        <div class="event-list" #listPanel>
            <div class="list-content" #listContent>
                <div class="table-responsive">
                    <table class="table table-hover table-sm bordered mb-0" [ngClass]="{ 'table-fixed': !tableExpanded }">
                        <thead>
                            <tr>
                                <th class="ellipsis" scope="col">{{ "OBJECT" | translate }}</th>
                                <th class="ellipsis" scope="col" [ngClass]="{ 'd-none': !columns.date_time }">{{ "DATE/TIME" | translate }}</th>
                                <th
                                    class="ellipsis"
                                    [ngStyle]="{
                                        'width.%': 40
                                    }"
                                    scope="col"
                                    [ngClass]="{ 'd-none': !columns.message }"
                                >
                                    {{ "MESSAGE" | translate }}
                                </th>
                                <th class="ellipsis" scope="col" [ngClass]="{ 'd-none': !columns.rule }">{{ "RULE" | translate }}</th>
                                <th class="text-end w-50px" scope="col">
                                    <mat-button-toggle-group name="expand" aria-label="expand" [(ngModel)]="tableExpanded" class="secondary xs">
                                        <mat-button-toggle [value]="1" *ngIf="!tableExpanded" class="secondary" title="{{ 'EXPAND' | translate }}"
                                            ><fa-icon icon="expand-alt" rotate="45" size="sm"></fa-icon
                                            ><span class="sr-only">{{ "EXPAND" | translate }}</span></mat-button-toggle
                                        >
                                        <mat-button-toggle [value]="0" *ngIf="tableExpanded" class="secondary" title="{{ 'CONTRACT' | translate }}"
                                            ><fa-icon icon="compress-alt" rotate="45" size="sm"></fa-icon
                                            ><span class="sr-only">{{ "CONTRACT" | translate }}</span></mat-button-toggle
                                        >
                                    </mat-button-toggle-group>
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="events.length && !loading">
                            <tr *ngFor="let event of events$ | async">
                                <td
                                    [ngClass]="{ 'ellipsis minw-120px': !tableExpanded }"
                                    title="{{ getObjectType(event.object_type) | desnake }}{{ event.object_name ? ' - ' + event.object_name : '' }}"
                                >
                                    <zx-status-full
                                        [model]="event"
                                        [status]="event.event_type | statusClass"
                                        text="{{ getObjectType(event.object_type) | desnake }}{{ event.object_name ? ' - ' + event.object_name : '' }}"
                                        [navigateOnClick]="true"
                                    ></zx-status-full>
                                </td>
                                <td
                                    [ngClass]="{ ellipsis: !tableExpanded, 'd-none': !columns.date_time }"
                                    title="{{ event.event_date | date : 'MMM d, y, h:mm:ss a' }}"
                                >
                                    {{ event.event_date | date : "MMM d, y, h:mm:ss a" }}
                                </td>
                                <td [ngClass]="{ ellipsis: !tableExpanded, 'd-none': !columns.message }" title="{{ event.message }}">
                                    <span class="d-inline" [innerHtml]="event.message"></span>
                                </td>
                                <td
                                    [ngClass]="{ ellipsis: !tableExpanded, 'd-none': !columns.rule }"
                                    title="{{ event.error_group | uppercase | translate }} - {{ event.error_code | uppercase | translate }}"
                                >
                                    <span *ngIf="event.error_group && event.error_code && (event.event_type === 'error' || event.event_type === 'warning')"
                                        >{{ event.error_group | uppercase | translate }} - {{ event.error_code | uppercase | translate }}</span
                                    >
                                    <span *ngIf="!event.error_group || !event.error_code || event.event_type === 'info'">-</span>
                                </td>
                                <td class="text-end">
                                    <a
                                        href="javascript:void(0)"
                                        *ngIf="
                                            (event.event_type === 'error' || event.event_type === 'warning') &&
                                            event.object_type !== 'remote_access' &&
                                            event.object_type !== 'task_sets'
                                        "
                                        (click)="createIncident(event)"
                                        title="{{ 'OPEN_INCIDENT' | translate }}"
                                        class="me-1"
                                    >
                                        <fa-icon icon="file-exclamation" size="sm"></fa-icon><span class="sr-only">{{ "OPEN_INCIDENT" | translate }}</span>
                                    </a>
                                    <a
                                        href="javascript:void(0)"
                                        *ngIf="
                                            event.event_type === 'error' ||
                                            (event.event_type === 'warning' &&
                                                (userPermissions.is_zixi_support_write || userPermissions.is_zixi_admin || userPermissions.is_admin))
                                        "
                                        (click)="manageEvent(event)"
                                        title="{{ 'MANAGE' | translate }}"
                                    >
                                        <fa-icon icon="cog" size="sm" class="ms-1"></fa-icon><span class="sr-only">{{ "MANAGE" | translate }}</span>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="(total$ | async) === 0 && events.length">
                            <tr>
                                <td [attr.colspan]="2 + (columns.rule ? 1 : 0) + (columns.message ? 1 : 0) + (columns.date_time ? 1 : 0)" class="text-center">
                                    {{ "NO_RESULTS" | translate }}
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!events.length && !loading">
                            <tr>
                                <td [attr.colspan]="2 + (columns.rule ? 1 : 0) + (columns.message ? 1 : 0) + (columns.date_time ? 1 : 0)" class="text-center">
                                    {{ "NO_EVENTS" | translate }}
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="(!events && loading) || (events && loading)">
                            <tr>
                                <td [attr.colspan]="2 + (columns.rule ? 1 : 0) + (columns.message ? 1 : 0) + (columns.date_time ? 1 : 0)" class="text-center">
                                    {{ "LOADING" | translate }}...
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="list-bottom" #listBottom>
                <div class="d-flex justify-content-between mt-2">
                    <!-- Pagination -->
                    <div>
                        <ngb-pagination
                            *ngIf="events && events.length"
                            (pageChange)="pageChange($event)"
                            [collectionSize]="total$ | async"
                            [(page)]="page"
                            [pageSize]="pageSize"
                            [maxSize]="2"
                        ></ngb-pagination>
                    </div>
                    <!-- Page Size -->
                    <div>
                        <div class="form-inline">
                            <div class="form-group mb-0 stay-inline">
                                <label for="pageSize">{{ "ROWS" | translate }}</label>
                                <select
                                    class="form-control minw-70px ms-2"
                                    name="pageSize"
                                    [(ngModel)]="selectedPageSize"
                                    (ngModelChange)="pageSizeChanged($event)"
                                >
                                    <option ngValue="auto">{{ "AUTO" | translate }}</option>
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
