import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";
import { Constants } from "../../constants/constants";

import { EventsComponent } from "./events.component";

const eventRoutes: Routes = [
    {
        path: Constants.urls.logs.events,
        component: EventsComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.EVENTS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/LoDUH"
                }
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(eventRoutes)],
    exports: [RouterModule]
})
export class EventsRouting {}
