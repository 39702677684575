import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";
import { MatDialogModule } from "@angular/material/dialog";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { EventsComponent } from "./events.component";

// Routes
import { EventsRouting } from "./events.routing";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        EventsRouting,
        MatDialogModule
    ],
    declarations: [EventsComponent],
    providers: []
})
export class EventsModule {}
